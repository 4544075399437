import React, { useEffect } from "react"
import SharePlayedImage from '../images/share-played.jpg';
import Layout from "../components/layout";
import SEO from "../components/seo";

const TestPage = ({ location }) => {

    return (
        <Layout showHeader={false}>
            <h1>test: {process.env.GATSBY_PIING_HOST}</h1>
        </Layout>
    )
}

export default TestPage